import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Formulario from "../components/Formulario"


import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformaComunidad() {

    gsap.registerPlugin(ScrollTrigger);

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTriggers = [];
        scrollTriggers = animateReveal(revealRefs.current);
        return () => {
            scrollTriggers.forEach((t) => t.kill(true));
        };
    }, []);

    const addToRevealRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    // end gsap

    return (
        <Layout>
            <Helmet
                title="Empresa de reformas | Pide presupuesto ya"
                meta={[
                    { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
                ]}
            />
            <div className="bg-[url('https://reformas.top/background/reformas-de-comunidades.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
                <div className=" container flex flex-col xl:flex-row justify-between py-5">
                    <div className=" flex items-center justify-center 2xl:px-10">
                        <h1 className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10">
                            <span class="text-white"> <strong>Reformas de Chalets</strong>: adosados, pareados y unifamiliares en
                            </span>
                        </h1>
                    </div>
                    <div>
                        <Formulario />
                    </div>
                </div>
            </div>
            <section>
                <div className="container my-20 ">
                    <div className="flex flex-col lg:flex-row items-center  lg:w-11/12 mx-auto">
                        <div className="basis-1/2 mb-5">
                            <StaticImage src="../images/mejores-reformas-comunidades.webp" alt="Reformar una casa para alquilarla" />
                        </div>
                        <div className="basis-1/2 lg:rounded-r-3xl p-5 lg:p-10">
                            <p>Mantener en buen estado las instalaciones y áreas donde habita una comunidad de vecinos suele ser la
                                prioridad de sus propietarios.<br /> Por otra parte, a veces surge la necesidad de hacer ciertos cambios
                                estructurales para propiciar el bienestar colectivo.<br /> Probablemente ni tus vecinos ni tú saben nada de
                                construcción pero notan que las condiciones del entorno han sufrido ciertos deterioros y necesitan ayuda
                                urgente. <br />Te contamos que llegaste al sitio correcto y ya no tienes nada de qué preocuparte, nosotros
                                podemos apoyarlos para llevar a cabo las mejores reformas de comunidades enciduad, continúa leyendo y
                                entérate cómo lo hacemos.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section
                style={{ "background": "transparent linear-gradient(0deg, #f2f4f9 0%, #FFFFFF00 30%) 0% 0% no-repeat padding-box" }}
            >
                <div className="container  mb-20">
                    <div className="lg:max-w-5xl mx-auto mb-20">
                        <h2 className="text-4xl lg:text-6xl text-center lg:leading-normal">Precios de reformas de comunidades enciduad
                        </h2>
                    </div>
                    <div className="mb-20">
                        <StaticImage src="../images/precios-de-reformas-de-comunidades.webp" alt="Precios de reformas de comunidades" />
                    </div>
                    <div className="lg:max-w-5xl mx-auto text-center">
                        <p>
                            El primer interrogante que surge es ¿cuánto cuestan las reformas de comunidades enciduad? La respuesta
                            depende de algunos factores como la magnitud de las mismas y los materiales a utilizar, los costes oscilan
                            entre 5.000 € y 35.000 €. <br />Es bueno que sepas que en Reformas Top llevamos muchos años haciendo este
                            tipo de
                            trabajos.<br />En nuestra empresa de reformas enciduad contamos con un equipo de profesionales cualificados
                            para hacer transformaciones increíbles tanto en viviendas particulares como en comunidades enteras.
                        </p>
                    </div>
                </div>
                <div className="container  pb-20 mb-20 ">
                    <div className=" lg:max-w-4xl  	mx-auto text-center mb-10">
                        <h2 className="mb-5 lg:leading-normal">Reformas pequeñas de comunidades enciduad</h2>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto">
                        <div className="basis-1/2  lg:rounded-l-3xl p-5 lg:p-10">
                            <p>Además de las reformas de casas, que por cierto hacemos muy bien, también nos especializamos en la
                                rehabilitación de áreas comunes pequeñas que necesiten ser mejoradas o simplemente para mantenerlas en
                                óptimas condiciones de funcionamiento. En Reformas Top estamos disponibles para solventar todo tipo de
                                situaciones de manera urgente, como por ejemplo la rotura de una tubería o la caída de un techo. Este tipo
                                de obras no pueden esperar y con solo llamarnos acudimos al rescate. Ahora bien, recuerda que la
                                construcción es nuestro fuerte y si lo que buscas es hacer reformas de cocinas pequeñas al mejor precio o
                                reformas de  aquí estamos para ti. Accede a este enlace
                                https://refpisos para que conozcas todo lo que hacemos.
                            </p>
                        </div>
                        <div className="basis-1/2 mb-5">
                        <StaticImage src="../images/reformas-pequenas-de-comunidades.webp" alt="Reformas pequeñas de comunidades" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="flex flex-col-reverse lg:flex-row items-center lg:w-11/12 mx-auto container">
                    <div className="basis-1/2 p-5 lg:p-10">
                        <h2 className="mb-5">Presupuestos para reformas casas</h2>
                        <p>
                            Hemos realizado infinidad de trabajos con acabados impecables. Ofrecemos ideas novedosas y adaptadas a las
                            necesidades de toda la comunidad. Diseñamos proyectos que sean convenientes para todos los involucrados. El
                            primer paso consiste en hacer un diagnóstico exhaustivo de la propiedad a fin de determinar las situaciones
                            problemáticas y presentar propuestas que sean funcionales sin descuidar la estética. Nos reunimos con los
                            interesados para ofrecer todo el asesoramiento requerido. Para propiciar una adecuada habitabilidad tal vez
                            sea necesario hacer cambios estructurales destinados a mejorar la seguridad, eliminar ciertas barreras
                            arquitectónicas o modificar el portal con la finalidad de incrementar la luminosidad. Asimismo, con la
                            rehabilitación de algunas fachadas podemos aumentar la eficiencia energética que se traduce en un ahorro
                            económico significativo para los vecinos a largo plazo. Los garajes y trasteros pueden ser modificados para
                            proteger mejor los coches y acondicionar los espacios para almacenar enseres. En Reformas Top queremos lo
                            mejor para ti. Sean obras urgentes, necesarias o de mejora, nuestros especialistas con mucho gusto te
                            ofrecen las asesorías pertinentes para que vivas en un entorno agradable, cómodo y seguro.
                        </p>
                    </div>
                    <div className="basis-1/2 flex justify-center mb-5">
                    <StaticImage src="../images/reformas-baratas-casas.webp" alt="Presupuestos para reformas casas" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}